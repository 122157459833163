<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" alt="logo" contain class="me-3"></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="
                  require(`@/assets/images/3d-characters/illustration-reset-password-${
                    $vuetify.theme.dark ? 'dark' : 'light'
                  }.png`)
                "
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pt-16">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">Reset Password 🔒</p>
                  <p class="mb-2">Your new password must be different from previously used passwords</p>
                </v-card-text>

                <v-alert color="warning" text>
                  <p class="font-weight-semibold mb-1">Ensure that these requirements are met</p>
                  <p class="text-sm mb-0">Minimum 8 characters long, uppercase &amp; number</p>
                </v-alert>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="password"
                      id="new-password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="New Password"
                      placeholder="············"
                      :rules="[validators.required, validators.passwordValidator]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      class="mb-3"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirmPassword"
                      id="confirm-password"
                      outlined
                      :type="isConfirmPasswordVisible ? 'text' : 'password'"
                      label="Confirm Password"
                      placeholder="············"
                      :rules="[validators.required, validators.confirmedValidator(confirmPassword, password)]"
                      :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    ></v-text-field>

                    <v-btn block color="primary" type="submit" class="mt-4 set-new-password"> Set New Password </v-btn>
                  </v-form>
                </v-card-text>

                <!-- back to login -->
                <v-card-actions class="d-flex justify-center align-center mt-2">
                  <router-link :to="{ name: 'auth-login-v2' }" class="d-flex align-center text-sm back-to-login">
                    <v-icon size="24" color="primary">
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { ref } from '@vue/composition-api';
import themeConfig from '@themeConfig';
import { useRouter } from '@core/utils';
import store from '@/store';
import { confirmedValidator, passwordValidator, required } from '@core/utils/validation';
import { eventBus } from '@/main';

export default {
  setup() {
    const { router } = useRouter();
    const isPasswordVisible = ref(false);
    const isConfirmPasswordVisible = ref(false);
    const password = ref('');
    const confirmPassword = ref('');
    const errorMessages = ref([]);
    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    store
      .dispatch('auth/verifyToken', router.currentRoute.params.token)
      .then((userTwoFaData) => {
        if (userTwoFaData.is2faEnabled) {
          eventBus.$emit('2fa-dialog.show', ('2fa-dialog.show', userTwoFaData));
        }
      })
      .catch(() => {
        router.push({ name: 'auth-login' });
      });

    const handleFormSubmit = () => {
      if (valid.value) {
        store
          .dispatch('auth/resetPassword', {
            password: password.value,
            confirmPassword: confirmPassword.value,
            token: router.currentRoute.params.token,
          })
          .then(() => {
            router.push('/');
          })
          .catch(error => console.error(error));
      } else {
        validate();
      }
    };

    return {
      validators: { required, passwordValidator, confirmedValidator },
      handleFormSubmit,
      errorMessages,
      form,
      valid,
      validate,
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
